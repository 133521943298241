import { Button } from '../../../ui';
import { useTranslate } from '@tolgee/react';

type AppointmentJoinFullScreenButtonType = {
  tileId: number;
}
export const AppointmentJoinFullScreenButton = ({ tileId }: AppointmentJoinFullScreenButtonType) => {
  const { t } = useTranslate();

  const handleFullScreen = (tileId: number) => {
    const element = document.getElementById(`video-tile-${tileId}`);
    if (element && element.requestFullscreen) {
      element.requestFullscreen();
    }
  };
  return <Button
    onClick={() => handleFullScreen(tileId)} style={{position: 'relative', zIndex: 100}}>
    {t('features.AppointmentJoinChatConfirm.fullScreenBtn.text')}
  </Button>;
};