import { useTranslate } from '@tolgee/react';
import { DeviceLabelTriggerStatus, DeviceLabels, useDeviceLabelTriggerStatus, useMeetingManager } from 'amazon-chime-sdk-component-library-react';
import { MeetingSessionConfiguration } from 'amazon-chime-sdk-js';
import { Button } from 'antd';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import { AppointmentTypeEnum } from '../../../common/enums/appointmentTypeEnum';
import { UserRoleEnum } from '../../../common/enums/userRoleEnum';
import { useAppDispatch } from '../../../common/hooks/store';
import { errorAction } from '../../../common/middlewares/listener';
import { meetingUrlRewriter } from '../../../common/utils/buildQueryString';
import { formatDateWithDayJS } from '../../../common/utils/dateFormat';
import { Appointment } from '../../appointment/interfaces/appointment';
import { AppointmentDaoService } from '../../appointment/services/AppointmentDaoService';
import { getAttendeeByRole } from '../../appointment/utils/helpers';
import useModal from '../../ui';
import { AppointmentModalPermission } from '../components/AppointmentModalPermission/AppointmentModalPermission';
import Camera from '../components/svg/Camera';
import Microphone from '../components/svg/Microphone';
import { MediaConfig } from '../interfaces/mediaConfig';
import styles from './appointment-join-meeting-details.module.scss';

const useMeetingDetailsModal = (appointment: Appointment) => {
  const { t } = useTranslate();
  const { Modal, hideModal, showModal } = useModal({});
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const meetingManager = useMeetingManager();
  const isVideo = appointment.type === AppointmentTypeEnum.video || appointment.type === AppointmentTypeEnum.hologram;

  const closeModal = () => {
    hideModal();
    navigate(-1);
  };

  const openMeetingSession = async () => {
    const data = await AppointmentDaoService.connect(appointment?.id);
    const meetingSessionConfiguration = new MeetingSessionConfiguration(
      data.meetingResponse.Meeting,
      data.attendeeResponse.Attendee,
    );

    if (meetingSessionConfiguration['urls']) {
      meetingSessionConfiguration['urls']['urlRewriter'] = meetingUrlRewriter;
    }
    meetingSessionConfiguration['meetingFeatures'].contentMaxResolution.videoFrameRate = 30;
    meetingSessionConfiguration['meetingFeatures'].contentMaxResolution.videoHeight = 2160;
    meetingSessionConfiguration['meetingFeatures'].contentMaxResolution.videoWidth = 3840;
    meetingSessionConfiguration['meetingFeatures'].videoMaxResolution.videoFrameRate = 30;
    meetingSessionConfiguration['meetingFeatures'].videoMaxResolution.videoHeight = 2160;
    meetingSessionConfiguration['meetingFeatures'].videoMaxResolution.videoWidth = 3840;
    if (meetingSessionConfiguration['videoUplinkBandwidthPolicy']?.setHighResolutionFeatureEnabled) {
      meetingSessionConfiguration['videoUplinkBandwidthPolicy'].setHighResolutionFeatureEnabled(true);
    }

    await meetingManager.join(meetingSessionConfiguration, {
      deviceLabels: isVideo ? DeviceLabels.AudioAndVideo : DeviceLabels.Audio,
    });

    await meetingManager.start();
  };

  const MeetingDetailsModal = useCallback(
    ({ setMediaConfig }: { setMediaConfig: (config: MediaConfig) => void }) => {
      const status: DeviceLabelTriggerStatus = useDeviceLabelTriggerStatus();
      const [showMicrophoneAndCameraAccessModal, setShowMicrophoneAndCameraAccessModal] = useState(false);
      const [ isVideoDisabled, setVideoDisabled ] = useState(true);
      const [ isMicrophoneMuted, setMicrophoneMuted ] = useState(true);

      const joinAppointment = async () => {
        try {
          await openMeetingSession();
          setMediaConfig({
            isMicrophoneMuted,
            isVideoDisabled
          });
          hideModal();
          await AppointmentDaoService.dial(appointment.type);
        } catch (err) {
          console.log('[ERROR][JOIN_APPOINTMENT]', err);
          dispatch(errorAction(new Error(t('features.useMeetingDetailsModal.errJoin'))));
        }
      };

      const patient = getAttendeeByRole(appointment.attendees, UserRoleEnum.User);
      const tester = getAttendeeByRole(appointment.attendees, UserRoleEnum.Tester);

      const getPatientOrTesterLabel = (): string => {
        let label = t('features.useMeetingDetailsModal.joinMdl.noPatient');

        if (tester) {
          label = t('features.useMeetingDetailsModal.joinMdl.tester', { testerSsiId: tester.ssiId });
        } else if (patient) {
          label = t('features.useMeetingDetailsModal.joinMdl.patient', {
            patientName: `${patient.firstName} ${patient.lastName}`
          });
        }

        return label;
      };

      const messages: Record<AppointmentTypeEnum, string> = {
        [AppointmentTypeEnum.video]: t('features.useMeetingDetailsModal.joinMessages.video'),
        [AppointmentTypeEnum.audio]: t('features.useMeetingDetailsModal.joinMessages.audio'),
        [AppointmentTypeEnum.text]: '',
        [AppointmentTypeEnum.hologram]: t('features.useMeetingDetailsModal.joinMessages.video'),
      };

      const togglePeriphery = async (
        mediaStreamConstraints: MediaStreamConstraints,
        toggleValue: boolean,
        togglePeripheryMethod: (value: boolean) => void
      ): Promise<void> => {
        try {
          if (status === DeviceLabelTriggerStatus.DENIED || status === DeviceLabelTriggerStatus.UNTRIGGERED) {
            const stream: MediaStream = await navigator.mediaDevices.getUserMedia(mediaStreamConstraints);
            stream.getTracks().forEach(track => track.stop());
          }
          togglePeripheryMethod(toggleValue);
        } catch (err) {
          // Error will be thrown in case when access to media is denied.
          // Let's show the pop-up in such case
          setShowMicrophoneAndCameraAccessModal(true);
        }
      };

      return (
        <div className={styles.wrap}>
          <AppointmentModalPermission
            appointment={ appointment }
            showMicrophoneAndCameraAccessModal={ showMicrophoneAndCameraAccessModal }
            setShowMicrophoneAndCameraAccessModal={ setShowMicrophoneAndCameraAccessModal } />
          <Modal onCancel={closeModal}>
            <p className={styles.title}>{formatDateWithDayJS(appointment?.startDatetime, 'MMMM DD, YYYY. h:mm a')}</p>
            <p className={styles.patient}>
              {getPatientOrTesterLabel()}
            </p>
            <p className={styles.text}>{messages[appointment.type]}</p>
            <div className={styles.optionsBlock}>
              {isVideo && <Button
                type="default"
                icon={<Camera className={styles.optionsIcon} disabled={isVideoDisabled} />}
                className={styles.buttonOption}
                onClick={ () => togglePeriphery({ audio: true, video: true }, !isVideoDisabled, setVideoDisabled) }
              >
                {t('features.useMeetingDetailsModal.videoBtn.text')}
              </Button>}
              <Button
                type="default"
                icon={<Microphone className={styles.optionsIcon} disabled={isMicrophoneMuted} />}
                className={styles.buttonOption}
                onClick={ () => togglePeriphery({ audio: true }, !isMicrophoneMuted, setMicrophoneMuted) }
              >
                {t('features.useMeetingDetailsModal.audioBtn.text')}
              </Button>
            </div>
            <div className={styles.buttonsBlock}>
              <Button
                type="primary"
                className={styles.buttonPrimary}
                onClick={joinAppointment}
              >
                {t('features.useMeetingDetailsModal.joinBtn.text')}
              </Button>
              <Button
                type="primary"
                className={styles.buttonPrimaryLow}
                onClick={closeModal}
              >
                {t('features.useMeetingDetailsModal.closeBtn.text')}
              </Button>
            </div>
          </Modal>
        </div>
      );},
    [Modal]
  );

  return {
    MeetingDetailsModal,
    showMeetingDetailsModal: showModal
  };
};

export default useMeetingDetailsModal;
